$main-grey: #333333;
$light-grey: #6f6764;
$lighter-grey: #cccccc;
$product-tab-grey: #424242;
$main-blue: #003768;
$light-blue: #61a6d1;
$lighter-blue: #d7e9f3;
$main-red: #983120;
$main-green: #03471b;
$bg-grey: #e2e1e0;
$table-outer-border: #6F6567;
$table-inner-border: #DFE4E7;

@mixin main-fontfamily {
	font-family: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif;
}

@mixin secondary-fontfamily {
	font-family: Arial, Helvetica, sans-serif;
}

@mixin default-ul {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}

body
{
	background: #FFF;	
	background-attachment: fixed;
	background-image: url('../images/steel-back.jpg');
	background-position: center bottom;
	background-repeat: no-repeat;
	font-size: 12px;
	@include
	main-fontfamily;
	margin: 0;
	padding: 0;
}

* {
	outline: 0px;
}

img {
	border: none;
}

a {
	text-decoration: none;
    color: $main-grey;
	text-decoration: none !important;
	
	&.hoverunderline:hover
	{
		text-decoration: underline !important;
	}

	&:hover
	{
	}
    &:visited
    {
        color: $main-grey;
    }
	
	.SecondaryLink
	{
        color: $main-blue;

        &:visited
        {
            color: $main-blue;
        }
	}
}

.Ellipsis
{
	overflow: hidden;
	white-space: normal;
}

.Required
{
	font-size: 14px;
	margin-left: 3px;
	color: $main-red;
}

.clearfix:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.clear {
	clear: both;
} 

.clearfix {
	display: inline-block;
}

html[xmlns] .clearfix {
	display: block;
}
 
* html .clearfix {
	height: 1%;
}

h1
{
    border: 1px solid $main-red;
    color: $main-red;
    display: block;
	font-size: 24px;
	margin: 0px;
    padding: 7px 10px;
    text-transform: uppercase;
}
h2 {

	font-size: 16px;
}
.SpanishFlag, .AmericanFlag
{
	display: block;
	font-size: 11px;
	float: left;
	margin-bottom: 15px;
	text-align: center;

	img
	{
		height: 25px;
	}
}

.Print
{
	display: block;
	background: url('/images/print.png') no-repeat center left;
	font-size: 10px;
	text-transform: uppercase;
	font-weight: bold;
	height: 30px;
	padding: 10px 0 0 30px;
}

.WarningImage
{
	display: block;
	background: url('/images/Notice.png') no-repeat center;
	height: 75px;
}

.RedDivider
{
	background: url('/images/leftContent-Divider.png') no-repeat center;
	display: block;
	height: 2px;
}

.RedBoldText
{
	color: $main-red;
	font-weight: bold;
}

.AddToWishList
{
    background: url('/images/WishList-Add.png') no-repeat;
	width: 120px;
    padding-left: 30px;
}

.DeleteFromWishList
{
	width: 160px;
}

.AddToWishList, .DeleteFromWishList
{
    display: block;
    font-size: 14px;
    font-weight: bold;
	height: 27px;
    margin: 0px auto;
	padding-top: 5px;
}
#site {
    background-color: #ffffff;
    margin: 0 auto;
    position: relative;
    width: 1001px;

    #corner-Right {
        position: absolute;
        right: 0;
        top: 16;
        z-index: 2;
    }

    #corner-Top {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
    }

    #header {
        height: 165px;
        padding-top: 24px;
        width: 100%;

        .search-section {
            @include default-ul;
            display: block;
            height: 78px;
            width: 100%;
            z-index: 3;

            .logo {
                display: block;
                float: left;

                a {
                    background: url('../images/logo.png') no-repeat;
                    display: block;
                    height: 78px;
                    width: 200px;
                }
            }

            .search {
                border-bottom: solid 1px #000000;
                display: block;
                float: right;
                height: 78px;
                margin-right: 21px;
                width: 745px;

                a {
                    background: url('../images/search.jpg') no-repeat;
                    display: block;
                    float: right;
                    height: 39px;
                    margin-right: 15px;
                    margin-top: 10px;
                    width: 38px;
                }

                .input {
                    border: solid 1px #c5c2c1;
                    float: right;
                    height: 36px;
                    margin-right: 2px;
                    margin-top: 10px;
                    width: 181px;

                    input {
                        color: $light-grey;
                        border: 0px;
                        box-shadow: none;
                        font-size: 12px;
                        margin-left: 11px;
                        margin-top: 10px;
                        padding: 0 !important;
                        text-transform: lowercase;
                        width: 160px;
                    }
                }
            }
        }

        #navigation {
            @include default-ul;
            margin-left: 244px;

            .nav-item {
                border-top: solid 5px transparent;
                display: block;
                float: left;
                position: relative;

                &:hover,
                &.selected {
                    border-top: solid 5px $main-blue;
                }

                a.main-link {
                    color: $main-blue;
                    display: block;
                    font-size: 14px;
                    font-weight: bold;
                    padding-bottom: 10px;
                    padding-top: 7px;
                    text-transform: uppercase;
                }

                .nav-item-secondary {
                    @include default-ul;
                    display: none;
                    position: absolute;
                    z-index: 500;

                    .nav-item-secondary-item {
                        display: block;
                        padding-right: 5px;
                        position: relative;

                        a.secondary-link {
                            background-color: #c8e0ef;
                            border-top: solid 1px #ffffff;
                            color: $main-blue;
                            display: block;
                            padding: 6px 10px 6px 10px;
                            white-space: nowrap;
                        }

                        &:hover {
                            background: url('../images/nav-hover.png') scroll right top no-repeat;

                            a {
                                background-color: #61a6d1;
                                color: #ffffff;
                            }
                        }

                        .nav-item-tertiary {
                            @include default-ul;
                            display: none;
                            opacity: 1;
                            position: absolute;
                            top: 0px;

                            li {
                                display: block;

                                a {
                                    background-color: #61a6d1;
                                    border-top: solid 1px #ffffff;
                                    color: #ffffff;
                                    display: block;
                                    padding: 6px 10px 6px 10px;
                                    white-space: nowrap;

                                    &:hover {
                                        background-color: #003768;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .divider {
                background: url('../images/nav-dot.png') no-repeat;
                display: block;
                float: left;
                height: 4px;
                margin-left: 14px;
                margin-right: 14px;
                margin-top: 20px;
                width: 5px;
            }

            .nav-item-wishlist {
                float: right;
                font-weight: bold;
                margin-right: 30px;
                padding-top: 11px;

                a {
                    background: url('/images/WishList-NavArrow.png') no-repeat center left;
                    color: $light-grey;
                    display: block;
                    font-size: 14px;
                    padding-left: 14px;
                    padding-top: 1px;
                }
            }
        }
    }

    #content {
        min-height: 600px;
        height: auto !important;
        height: 600px;
        width: 100%;

        .LeftInnerContent {
            float: left;
            width: 227px;

            .LeftUserContent {
                background: url('/Images/StandardLeftUserContent_Top.png') no-repeat;
                height: auto !important;
                height: 100px;
                margin-bottom: 20px;
                min-height: 100px;
                padding-top: 35px;
                position: relative;
                width: 227px;

                .LeftUserContentMiddle {
                    background: url('/Images/StandardLeftUserContent_Middle.png') repeat-y;
                    line-height: 13px;
                    padding: 0 45px 10px 20px;
                }

                .LeftUserContentBottom {
                    background: url('/Images/StandardLeftUserContent_Bottom.png') no-repeat;
                    display: block;
                    height: 7px;
                    position: absolute;
                    bottom: 0px;
                    width: 200px;
                }
            }

            .StandardNav {
                background: url('/Images/StandardLeftNav_BG.jpg') no-repeat;
                height: 418px;
                padding: 20px 0px;
                width: 200px;

                #LeftNavProductCatalog {
                    background: url('/Images/StandardLeftNav_ProductCatalog.png') no-repeat center top;
                    color: #fff;
                    display: block;
                    margin: 0 auto;
                    padding-top: 120px;
                    text-align: center;
                    width: 130px;
                }

                #LeftNavQuickShip {
                    background: url('/Images/StandardLeftNav_QuickShip.png') no-repeat center top;
                    color: #fff;
                    display: block;
                    margin: 30px auto;
                    padding-top: 90px;
                    text-align: center;
                    width: 130px;
                }
            }
        }

        .MainInnerContent {
            float: left;
            width: 704px;
            padding: 0 20px;
        }

        .LeftContent {
            float: left;
            width: 200px;

            .StandardNav {
                background: url('/Images/StandardLeftNav_BG.jpg') no-repeat;
                height: 418px;
                padding: 20px 0px;

                #LeftNavProductCatalog {
                    background: url('/Images/StandardLeftNav_ProductCatalog.png') no-repeat center top;
                    color: #fff;
                    display: block;
                    margin: 0 auto;
                    padding-top: 120px;
                    text-align: center;
                    width: 130px;
                }

                #LeftNavQuickShip {
                    background: url('/Images/StandardLeftNav_QuickShip.png') no-repeat center top;
                    color: #fff;
                    display: block;
                    margin: 30px auto;
                    padding-top: 90px;
                    text-align: center;
                    width: 130px;
                }
            }

            .categoriesNav {
                @include default-ul;
                background: url('../images/category-background.jpg') no-repeat;
                height: 350px;

                li.nav-item {
                    display: block;
                    margin: 0 5px;
                    width: 190px;

                    a.nav-item-link {
                        border-left: solid 1px #ffffff;
                        border-right: solid 1px #ffffff;
                        border-bottom: solid 1px #ffffff;
                        color: #ffffff;
                        display: block;
                        font-size: 17px;
                        padding: 11px 15px 10px 15px;
                        text-decoration: none;
                        text-transform: uppercase;

                        &:hover,
                        &.selected {
                            background: url('../images/category-hover.jpg') no-repeat;
                        }
                    }

                    &:first-child {
                        a {
                            border-top: solid 1px #ffffff;
                            margin-top: 5px;
                        }
                    }

                    &.sub-nav {
                        position: relative;

                        &:hover {
                            cursor: pointer;
                        }

                        .nav-item-secondary {
                            @include default-ul;
                            display: none;
                            opacity: 1;
                            position: absolute;
                            top: 0px;
                            z-index: 999;

                            li {
                                display: block;

                                a {
                                    background-color: #61a6d1;
                                    border-top: solid 1px #ffffff;
                                    color: #ffffff;
                                    display: block;
                                    padding: 6px 10px 6px 10px;
                                    white-space: nowrap;

                                    &:hover {
                                        background-color: #003768;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .searchFilters {
                #BackgroundWrapper {
                    background: $light-blue;
                    padding: 5px;
                    width: 190px;

                    #CatSubCatName, #FilterTitle {
                        border: 1px solid #fff;
                        color: #FFF;
                        display: block;
                        font-size: 18px;
                        font-weight: bold;
                        padding: 13px;
                        text-transform: uppercase;
                    }

                    #FilterMessage {
                        color: #FFF;
                        background: $main-blue;
                        display: block;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 19px;
                        margin-top: 10px;
                        padding: 8px;
                        text-align: center;
                        text-transform: uppercase;
                    }

                    .AttributeTypeFilters {
                        @include default-ul;
                        background: $lighter-blue;
                        padding: 10px;

                        .AttributeTypeFilter {
                            margin-bottom: 10px;

                            &.AddDivider {
                                border-bottom: 1px solid #000;
                            }

                            .AttributeTypeName {
                                @include secondary-fontfamily;
                                background: url('/images/ProductSearch_Expanded.png') no-repeat left center;
                                color: #000;
                                display: block;
                                font-size: 14px;
                                padding-left: 15px;
                                margin-bottom: 10px;

                                &.Collapsed {
                                    background: url('/images/ProductSearch_Collapsed.png') no-repeat left center;
                                }
                            }

                            .AttributeFilters {
                                @include default-ul;
                                padding-bottom: 10px;
                                padding-left: 10px;

                                .AttributeFilter {
                                    input {
                                        display: block;
                                        float: left;
                                    }

                                    .AttributeName {
                                        @include secondary-fontfamily;
                                        color: $main-blue;
                                        display: block;
                                        font-size: 13px;
                                        margin-bottom: 0px;
                                        margin-left: 5px;
                                        padding-top: 1px;
                                        float: left;
                                    }
                                }
                            }
                        }
                    }
                }

                #AddedBackground {
                    background: url('/images/category-background.jpg') no-repeat bottom right;
                    display: block;
                    width: 100%;
                    height: 93px;
                }
            }

            .Padding {
                padding: 5px;
            }
        }

        .MainContent {
            float: left;
            width: 731px;
            padding: 0 20px;
        }

        #HomeContainer {
            #RotatingBanner {
                @include default-ul;
                float: left;
                position: relative;
                width: 779px;

                li {
                    display: none;
                    height: 350px;
                    position: absolute;
                    width: 800px;

                    span {
                        background: url('/Images/Home_BannerMessageOverlay.png') no-repeat;
                        bottom: -20px;
                        color: $lighter-grey;
                        display: block;
                        font-size: 12px;
                        height: 155px;
                        left: 0;
                        line-height: 18px;
                        padding: 20px 60px 0px 25px;
                        position: absolute;
                        width: 187px;
                        z-index: 1;

                        strong {
                            text-transform: uppercase;
                            font-weight: bold;
                        }
                    }
                }
            }

            #BottomContainers {
                padding: 20px 0;

                .LeftBox {
                    background: url('/images/Home_LeftBox.png') no-repeat;
                    color: $main-blue;
                    display: block;
                    height: 210px;
                    float: left;
                    padding: 10px 60px 10px 30px;
                    width: 362px;

                    strong {
                        font-size: 14px;
                        font-weight: bold;
                    }

                    p {
                        font-size: 12px;
                    }
                }

                .Divider {
                    background: url('/images/Home_Border.png') no-repeat;
                    display: block;
                    height: 208px;
                    float: left;
                    width: 2px;
                }

                .ImageContainer {
                    display: block;
                    float: left;
                    height: 120px;
                    margin: 5px 29px 0px 29px;
                    padding-top: 124px;
                    width: 123px;

                    .ImageMessage {
                        display: block;
                        float: left;
                        height: 120px;
                        text-align: center;
                    }
                }

                .FirstImage {
                    background: url('/images/Home_ProductCatalog.png') no-repeat top center;

                    .FirstMessage {
                    }
                }

                .SecondImage {
                    background: url('/images/Home_QuickShip.png') no-repeat top center;

                    .SecondMessage {
                    }
                }

                .ThirdImage {
                    background: url('/images/Home_Estey.png') no-repeat top center;

                    .ThirdMessage {
                    }
                }
            }
        }

        .breadcrumb {
            background-color: #fff;
            margin: 0;

            span {
                color: $main-grey;
                font-size: 11px;
            }

            a {
                font-size: 11px;
            }
        }


        #CaseStudiesContainer {
            margin: 0;
            padding: 0;

            #ulCaseStudyResults {
                @include default-ul;
                clear: both;
                min-height: 100px;
                margin-top: 20px;
                margin-bottom: 20px;
                width: 100%;
                position: relative;
                display: inline-block;

                .CaseStudyResultItem {
                    border: 1px solid $light-grey;
                    color: $light-grey;
                    display: block;
                    float: left;
                    font-size: 12px;
                    margin: 0 0 20px 0;
                    padding: 4px;

                    .CaseStudyImage {
                        line-height: 214px;
                        text-align: center;
                        width: 214px;
                        float: left;
                        background-color: #eee;

                        img {
                            width: 214px;
                            vertical-align: middle;
                        }
                    }

                    .RightCol {
                        color: #333;
                        float: left;
                        width: 466px;
                        margin: 20px;

                        .CaseStudyTitle {
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 20px;
                            color: $main-red;
                            display: block;
                        }

                        .CaseStudyTeaserContent {
                            margin: 10px 0;
                            line-height: 1.5em;
                            display: block;
                        }

                        .ViewStoryButton {
                            background: url("/images/ProductSearch_ViewProduct.png") no-repeat;
                            color: #fff;
                            display: block;
                            font-weight: bold;
                            font-size: 14px;
                            height: 27px;
                            padding-top: 5px;
                            text-align: center;
                            text-transform: uppercase;
                            width: 138px;
                        }
                    }
                }
            }
        }

        #CaseStudyContainer {
            margin: 0 20px;
            margin-bottom: 30px;
            padding: 0;
            width: 700px;

            .CaseStudyTitle {
                border: solid 1px $main-red;
                color: $main-red;
                font-size: 16px;
                font-weight: 700;
                line-height: 20px;
                margin-top: 24px;
                margin-bottom: 14px;
                padding: 10px;
            }

            .CaseStudyImage {
                margin: 10px auto;
                text-align: center;

                img {
                    width: 560px;
                    border: solid 1px $main-grey;
                }
            }

            .CaseStudyDownLoad {
                display: inline-block;
                border-left: solid 1px #cccccc;
                border-right: solid 1px #cccccc;
                padding: 0 8px;

                a {
                    background-image: url('/images/Product_DownloadImage.png');
                    background-repeat: no-repeat;
                    background-position: 20px, center;
                    display: inline-block;
                    line-height: 1.3em;
                    padding: 10px 20px 10px 80px;
                    text-align: left;
                }

                a:hover {
                    background-color: #eee;
                }
            }

            .CaseStudyCopy {
                color: $main-grey;
                font-size: 12px;
                line-height: 1.5em;

                span { /* Copy Title */
                    display: block;
                    font-size: 14px;
                    margin: 6px 0;
                    font-weight: bold;
                }
            }
        }


        #CategoryContainer {
            .ViewAllButton {
                background: url('/images/Category_ViewButton.png') no-repeat;
                color: #fff;
                display: block;
                font-weight: bold;
                font-size: 14px;
                height: 27px;
                margin: 20px auto 10px auto;
                padding-top: 5px;
                text-align: center;
                text-transform: uppercase;
                width: 508px;
            }

            #SubCategoryList {
                @include default-ul;

                .Divider {
                    background: url('/Images/Category_DottedLine.png') center center no-repeat;
                    height: 22px;
                    margin-bottom: 15px;
                    width: 100%;
                }

                .SubCategoryListItem {
                    height: auto !important;
                    height: 228px;
                    min-height: 228px;

                    .SubCategoryImage {
                        float: left;
                        height: 228px;
                        line-height: 228px;
                        min-height: 228px;
                        width: 242px;
                        text-align: center;

                        img {
                            vertical-align: middle;
                        }
                    }

                    .SubCategoryInfo {
                        background: url('/images/Category_SubCategoryBG.png') no-repeat;
                        float: left;
                        height: auto !important;
                        height: 228px;
                        min-height: 228px;
                        padding: 20px 0 0 30px;
                        width: 429px;

                        .SubCategoryInfoBGWrapper {
                            background: url('/images/Category_SubCategoryBG.png') right;
                            padding: 0 0 10px 30px;

                            .SubCategoryInfoTitle {
                                color: $main-red;
                                display: block;
                                font-size: 14px;
                                font-weight: bold;
                            }

                            .SubCategoryInfoDescription {
                                color: $main-grey;
                                display: block;
                                font-size: 12px;
                                line-height: 14px;
                                padding: 10px 10px 10px 0;

                                ul {
                                    @include default-ul;

                                    li {
                                        list-style-type: disc;
                                        line-height: 15px;
                                        margin-left: 20px;
                                    }
                                }
                            }

                            .SubCategoryInfoButton {
                                background: url('/images/Category_ViewSubCategory.png') no-repeat;
                                color: #fff;
                                display: block;
                                font-weight: bold;
                                height: 29px;
                                padding-top: 5px;
                                text-align: center;
                                width: 135px;
                            }
                        }
                    }
                }
            }
        }

        #PhotoGalleryContainer {
            width: 716px;
        }

        #ulPhotoGalleryResults {
            @include default-ul;
            clear: both;
            min-height: 100px;
            margin-top: 20px;
            margin-bottom: 20px;
            width: 100%;
            position: relative;
            display: inline-block;

            .PhotoGalleryResultItem {                
                color: $light-grey;
                display: block;
                float: left;
                font-size: 12px;
                margin: 0 22px 22px 0;
                
                .PhotoGalleryImage {                   
                    cursor: pointer;                    
                    display: inline-block;                    
                    border: solid 1px $main-grey;
                    line-height: 214px;
                    padding: 4px;

                    img {
                        width: 214px;
                        vertical-align: middle;
                    }

                    .PhotoGalleryHover {
                        background-color: rgba(0,0,0,.3);
                        display: none;
                        position: absolute;                        
                        line-height: 214px;
                        width: 214px;
                        margin-top: -214px;
                    }
                }

                .PhotoGalleryPreviewModal {
                    display: none;
                }

                .vDialog {
                    .vDialogContent {
                        background-color: #fff;
                    }

                    .vDialogClose {
                        padding: 3px;
                        font-size: 14px;
                    }
                }

                .PhotoGalleryPreviewContent {
                    margin: 28px;
                    margin-bottom: 4px;

                    * { vertical-align:middle; }

                    img {
                        width: 600px;
                    }

                    .PhotoGalleryPreviewNext, .PhotoGalleryPreviewPrev {
                        display: inline-block;
                        background: url('/images/nav-hover.png') no-repeat 50%;                    
                        background-size:70%;
                        width:20px;
                        height:140px;
                        cursor:pointer;
                    }

                    .PhotoGalleryPreviewPrev {                        
                        background: url('/images/nav-hover.png') no-repeat 50%;                                               
                    }

                    .PhotoGalleryPreviewDesc {
                        display: block;
                        margin: 10px 0;
                        font-size: 11px;
                    }
                }
            }

            .PhotoGalleryResultItem:nth-child(3) {
                margin-right: 0;
            }
        }


        #ProductsSearchContainer {
            height: 1960px;

            #ulProductsSearchResults {
                @include default-ul;
                clear: both;
                min-height: 1848px;
                margin-top: 20px;
                width: 100%;

                .ProductsSearchResultItem {
                    border: 1px solid $light-grey;
                    color: $light-grey;
                    display: block;
                    height: 440px;
                    float: left;
                    font-size: 13px;
                    margin: 0 0 20px 0;
                    width: 227px;

                    .Image {
                        height: 240px;
                        line-height: 240px;
                        text-align: center;
                        width: 100%;

                        img {
                            vertical-align: middle;
                        }
                    }

                    .Name {
                        display: block;
                        height: 60px;
                        text-align: center;
                    }

                    .Dimensions {
                        display: block;
                        text-align: center;
                    }

                    .SKU {
                        display: block;
                        text-align: center;
                    }

                    .ViewProduct {
                        background: url('/images/ProductSearch_ViewProduct.png') no-repeat;
                        color: #FFF;
                        display: block;
                        font-size: 12px;
                        font-weight: bold;
                        height: 29px;
                        margin: 10px auto 0 auto;
                        padding-top: 5px;
                        text-align: center;
                        width: 135px;
                        text-transform: uppercase;
                    }

                    .AddToWishListNoImage, .DeleteFromWishListNoImage {
                        color: $main-blue;
                        display: block;
                        font-weight: bold;
                        text-align: center;
                    }

                    .Compare {
                        display: block;
                        margin: 0 auto;
                        padding-top: 4px;
                        width: 135px;

                        &.ToBeCompared {
                            width: 150px;
                        }

                        .CompareCheckbox {
                            cursor: pointer;
                            display: block;
                            float: left;

                            input {
                            }
                        }

                        .CompareText {
                            color: $light-grey;
                            cursor: pointer;
                            display: block;
                            float: left;
                            font-size: 13px;
                            height: 29px;
                            padding-left: 10px;
                            padding-top: 4px;
                            width: 98px;
                        }

                        .CompareButton {
                            background: url('/images/ProductSearch_Compare.png') no-repeat;
                            color: #222222;
                            display: block;
                            float: left;
                            height: 29px;
                            margin-left: 10px;
                            padding-top: 5px;
                            text-align: center;
                            width: 118px;
                        }
                    }
                }

                .Divider {
                    float: left;
                    height: 100px;
                    width: 22px;
                }

                .Clear { //Used for last li to clear in order to use overlay
                    float: none;
                    clear: both;
                }
            }
        }

        #ProductsComparisonContainer {
            width: 970px;

            #ExitComparison {
                display: block;
                margin: 14px 0 14px 0;
                text-align: center;

                span {
                    font-weight: bold;
                    font-size: 18px;
                }
            }

            #ProductsComparisonTable {
                border-collapse: collapse;
                width: 100%;

                td {
                    padding: 0;
                }

                .FirstRowColumn {
                    border-top: solid 1px $table-outer-border !important;
                }

                .LastRowColumn {
                    border-bottom: solid 1px $table-outer-border !important;
                }

                .FirstColumn {
                    background-color: #FFEBBE !important;
                    border-right: solid 1px $table-outer-border !important;
                    border-left: solid 1px $table-outer-border !important;
                    padding: 10px 5px;
                    text-align: left !important;
                    width: 175px;
                }

                .LastColumn {
                    border-right: solid 1px $table-outer-border !important;
                }

                .ProductsRow {
                    height: 350px;

                    td {
                        border-bottom: solid 1px $table-inner-border;
                        border-left: solid 1px $table-inner-border;
                        position: relative;

                        .Remove {
                            display: block;
                            position: absolute;
                            font-size: 16px;
                            font-weight: bold;
                            right: 5px;
                            top: 0;
                        }

                        .Product {
                            color: $main-grey;
                            display: block;
                            margin: 0 auto;
                            width: 190px;

                            .Image {
                                display: block;
                                height: 250px;
                                line-height: 250px;
                                text-align: center;
                                width: 100%;

                                img {
                                    vertical-align: middle;
                                }
                            }

                            .NameAndSku {
                                display: block;
                                height: 54px;

                                .Name {
                                    display: block;
                                    text-align: center;
                                }

                                .Sku {
                                    display: block;
                                    text-align: center;
                                }
                            }

                            .AddToWishList, .DeleteFromWishList {
                                margin: 10px auto;
                            }
                        }
                    }
                }

                .SpecificationsRow {
                    td {
                        background-color: #EEF6F9;
                        border-bottom: solid 1px $table-inner-border;
                        border-left: solid 1px $table-inner-border;
                        text-align: center;

                        &.ProductAttributeName {
                            font-weight: bold;
                            padding: 10px 5px;
                        }
                    }
                }

                .Header {

                    td {
                        background-color: $bg-grey;
                        color: $main-grey;
                        font-weight: bold;
                        padding: 10px;
                        text-transform: uppercase;
                    }
                }
            }
        }

        #ProductContainer {
            #ProductPadding {
                padding: 15px 20px 0px 20px;
            }

            #ProductInfo {
                display: block;
                height: 373px;

                .Header {
                    background-color: $lighter-blue;
                    color: $main-blue;
                    display: block;
                    font-size: 18px;
                    line-height: 20px;
                    margin: 0;
                    padding: 10px;
                    text-transform: uppercase;
                }

                #FullImages {
                    border: solid 1px $light-grey;
                    float: left;
                    height: 330px;
                    line-height: 330px;
                    text-align: center;
                    vertical-align: middle;
                    width: 330px;

                    img {

                        &.Selected {
                        }
                    }
                }

                .Info {
                    float: left;
                    width: 280px;
                    padding-left: 23px;

                    #ProductDescription {
                        height: 186px;
                        line-height: 19px;
                        padding-top: 10px;
                    }

                    div {
                        #DownloadProductImage,
                        #ViewVideo,
                        #View360Image {
                            background-image: url('/images/Product_ViewDownload.png');
                            background-repeat: no-repeat;
                            background-position: 7px, center;
                            border: 1px solid #eee;
                            border-radius: 4px;
                            color: $main-blue;
                            display: inline-block;
                            font-size: 12px;
                            font-weight: bold;
                            line-height: 1.3em;
                            margin: 2px;
                            padding: 7px;
                            padding-left: 50px;
                            text-align: left;
                            width: 84px;
                        }

                        #ViewVideo {
                            background-image: url('/images/Product_ViewVideo.png');
                            width: 58px;
                        }

                        #View360Image {
                            background-image: url("/images/Product_ViewDownload.png");
                            width: 58px;
                        }

                        #DownloadProductImage:hover,
                        #ViewVideo:hover,
                        #View360Image:hover {
                            background-color: #eee;
                            border: 1px solid #ccc;
                        }
                    }


                    #FindADealer {
                        background: url('/images/Product_FindADealer.png') no-repeat;
                        color: #FFF;
                        display: block;
                        font-size: 14px;
                        font-weight: bold;
                        height: 29px;
                        padding-top: 5px;
                        text-align: center;
                        text-transform: uppercase;
                        width: 256px;
                    }

                    .AddToWishList, DeleteFromWishList {
                        margin-top: 5px;
                    }
                }
            }

            #ProductTabs {
                @include default-ul;
                padding-left: 24px;

                .Tab {
                    background: url('/images/Product_Tab.png') no-repeat;
                    color: #000;
                    cursor: pointer;
                    display: block;
                    float: left;
                    font-size: 14px;
                    font-weight: bold;
                    height: 28px;
                    padding-top: 7px;
                    padding-left: 20px;
                    text-align: center;
                    text-transform: uppercase;
                    width: 124px;


                    &.Selected, &:hover {
                        background: url('/images/Product_TabActive.png') no-repeat;
                        color: #FFF;
                    }
                }
            }

            #TabContainers {
                color: $product-tab-grey;
                font-size: 13px;
                height: auto !important;
                min-height: 466px;
                position: relative;
                width: 648px;

                .TabContainer {
                    background: url('/images/Product_TabLine.jpg') no-repeat;
                    display: none;
                    padding: 34px 30px;

                    &.Selected {
                        display: block;
                    }

                    &#TabSpecificationsDiv {
                        #ProductSpecification {
                            @include default-ul;

                            li {
                                border-bottom: solid 1px $product-tab-grey;
                                height: 20px;
                                padding: 5px 0;

                                .AttributeTypeName {
                                    display: block;
                                    float: left;
                                    font-weight: bold;
                                    width: 178px;
                                }

                                .AttributeNames {
                                    display: block;
                                    float: left;
                                    width: 399px;
                                }
                            }
                        }

                        .DownloadAddlSpecifications {
                            color: $main-blue;
                            display: block;
                            font-size: 13px;
                            font-weight: bold;
                            padding-top: 15px;

                            a {
                                color: $main-blue;
                                text-decoration: underline;

                                &:selected {
                                    color: $main-blue;
                                }
                            }
                        }
                    }

                    &#TabLiteratureDiv {
                        a {
                            display: block;
                            float: left;
                            height: 20px;
                            margin-top: 25px;
                            margin-left: 80px;
                            text-align: center;
                            padding-top: 60px;

                            &.BrochureLink {
                                background: url('/images/Product_Literature_Brochure.jpg') no-repeat center top;
                                width: 50px;
                            }

                            &.AssemblyInstructionsLink {
                                background: url('/images/Product_Literature_Assembly.jpg') no-repeat center top;
                                width: 140px;
                            }

                            &.PricelistLink {
                                background: url('/images/Product_Literature_Pricelist.jpg') no-repeat center top;
                                width: 50px;
                            }
                        }
                    }

                    &#TabColorsDiv {
                        .ColorsList {
                            @include default-ul;

                            .ColorItem {
                                height: auto !important;
                                height: 102;
                                float: left;
                                min-height: 102;
                                padding-top: 15px;
                                width: 110px;

                                .Color {
                                    border-radius: 33px;
                                    display: block;
                                    height: 67px;
                                    margin: 0 auto;
                                    width: 67px;
                                }

                                .Name {
                                    color: $product-tab-grey;
                                    display: block;
                                    font-size: 12px;
                                    padding-top: 10px;
                                    text-align: center;
                                }
                            }
                        }
                    }

                    &#TabAccessoriesDiv {
                        #ProductAccessories {
                            @include default-ul;

                            li {
                                padding-top: 20px;

                                a {
                                    display: block;
                                    cursor: pointer;

                                    &:hover .AccessoryName {
                                        text-decoration: underline;
                                    }

                                    &:hover {
                                        text-decoration: none;
                                    }

                                    .AccessoryImage {
                                        border: solid 1px #6f6764;
                                        float: left;
                                        height: 93px;
                                        line-height: 93px;
                                        text-align: center;
                                        width: 93px;
                                        vertical-align: middle;

                                        img {
                                        }
                                    }

                                    .AccessoryInfo {
                                        color: $product-tab-grey;
                                        float: left;
                                        font-size: 13px;
                                        height: 93px;
                                        padding-left: 20px;
                                        position: relative;
                                        width: 457px;

                                        .AccessoryName {
                                            display: block;
                                            font-weight: bold;
                                        }

                                        .AccessoryDescription {
                                            display: block;
                                            height: 70px;
                                            line-height: 150%;
                                            width: 457px;
                                        }
                                    }
                                }
                            }

                            .Divider {
                                border-bottom: solid 1px #6f6764;
                                display: block;
                                height: 1px;
                                width: 100%;
                            }
                        }
                    }

                    .Header {
                        border-bottom: solid 1px $product-tab-grey;
                        color: $product-tab-grey;
                        display: block;
                        font-size: 13px;
                        padding-bottom: 3px;
                    }
                }

                .Background {
                    height: 466px;
                    position: absolute;
                    top: 0;
                    width: 40px;

                    &#BackgroundLeft {
                        background: url('/images/Product_TabLeftBG.png') no-repeat;
                        left: -40px;
                    }

                    &#BackgroundRight {
                        background: url('/images/Product_TabRightBG.png') no-repeat;
                        right: -40px;
                    }
                }
            }

            #Disclaimer {
                color: $product-tab-grey;
                display: block;
                font-style: italic;
                margin-top: 40px;
            }
        }
    }


    #PhotoGallery {
        ul {
            @include default-ul;
            height: 100px;
            padding-top: 10px;
        }

        li {
            display: block;
            float: left;
        }

        .MissingThumbImage,
        .ThumbImage {
            height: 88px;
            line-height: 88px;
            margin-left: 9px;
            margin-right: 9px;
            position: relative;
            text-align: center;
            width: 88px;
        }

        .ThumbImage {
            cursor: pointer;

            &.Selected, &:hover {
                .Border {
                    border: solid 5px #ffd87c;
                    height: 80px;
                    width: 80px;
                }
            }

            img {
                height: 90px;
                width: 90px;
                vertical-align: middle;
            }

            .Border {
                border: solid 1px #6f6764;
                left: 0;
                top: 0;
                height: 88px;
                width: 88px;
                display: block;
                position: absolute;
                z-index: 1;
            }
        }

        #ArrowLeft, #ArrowRight {
            cursor: pointer;
            display: inline-block;
            width: 28px;
            height: 48px;
            vertical-align: top;
            margin-top: 34px;
        }

        #ArrowLeft {
            background: url('/images/Product_Thumb_LeftArrow.png') no-repeat center;
        }

        #ArrowRight {
            background: url('/images/Product_Thumb_RightArrow.png') no-repeat center;
        }
    }

    #footer {
        background: #f2ede0 url('../images/footer-background.png') no-repeat;
        color: $main-grey;
        height: 162px;
        width: 100%;

        a {
            color: $main-grey;

            &:hover {
                color: $main-blue;
                text-decoration: underline;
            }
        }

        ul {
            @include default-ul;
            margin-left: 50px;
            margin-top: 40px;

            li {
                display: block;
                float: left;
                font-family: Verdana, Arial, Sans-Serif;
                font-size: 9px;

                &.footer-copyright {
                    line-height: 26px;
                    width: 267px;

                    #imgUsa {
                        display: block;
                        margin-bottom: 10px;
                        margin-top: 5px;
                    }

                    #aEsteyTennsco {
                        display: block;

                        img {
                            display: block;
                            float: left;
                        }

                        span {
                            display: block;
                            float: left;
                            padding-top: 1px;
                        }
                    }
                }

                &.footer-content {
                    line-height: 23px;
                    margin-left: 28px;
                }
            }
        }

        a {
        }
    }
}

.FlowPlayerContainer {
	padding: 30px;
	height: 350px;
	width: 600px;
}

/*#region vDialog */

.vDialogOverlay
{
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
    background-color: #000000;

	&.LoadingOverlay 
	{
		border-radius: 5px;
	}
}

.vDialog
{
    border: 10px solid rgba(0,0,0,.3);
    border-radius: 5px;
    display: none;

    .vDialogContent
    {
        background-color: #f1f1f1;
        border-radius: 5px;
        position: relative;

        &.vDialogContentWithFooter
        {
            padding-bottom: 55px;
        }

        .vDialogHeader
        {
            background-color: #e3e3e3;
            border-bottom: solid 1px #cccccc;
            box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3);
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            color: #414141;
            display: block;
            font-family: Tahoma, sans-serif;
            font-size: 14px;
            font-weight: bold;
            margin: 0px;
            padding: 15px 10px 15px 10px;
            text-shadow: 0 1px 0 #ffffff;

            span
            {
                color: $main-blue;
            }
        }

        .vDialogBody
        {
            padding: 10px;
        }
    }

    .vDialogClose
    {
        background-color: #ffffff;
        border: solid 1px #c1c0c0;
        border-radius: 5px;
        box-shadow: 0px 0px 10px #cccccc;
        color: #414141;
        display: block;
        font-size: 16px;
        padding: 3px 6px 3px 6px;
        position: absolute;
        right: 10px;
        text-decoration: none;
        top: 10px;
        transition: .2s linear;
        -webkit-transition: .2s linear;
        -moz-transition: .2s linear;

        &:hover
        {
            border-color: #e3e3e3;
            box-shadow: 0px 0px 10px #414141;
            color: #b10505;
        }
    }

    .vDialogContent
    {
        .vDialogBody > img.LoadingImage
        {
            display: block;
            float: left;
            height: 11px;
            margin-top: 3px;
            width: 16px;
        }

        .vDialogBody > span.LoadingText
        {
            color: #414141;
            display: block;
            float: left;
            font-size: 14px;
            font-family: Arial, 'DejaVu Sans', 'Liberation Sans', Freesans, sans-serif;
            padding: 0px 0px 0px 15px;
            text-shadow: 0 1px 0 #ffffff;
        }

        .vDialogFooter
        {
            background-color: #e3e3e3;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border-top: solid 1px #cccccc;
            bottom: 0px;
            box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3);
            clear: both;
            height: 45px;
            position: absolute;
            width: 100%;

            .vDialogFooterButtons
            {
                float: right;
                margin-right: 2px;
				padding: 5px;

                .button
                {
                    margin-top: 4px;
                }
            }

            .vDialogFooterContent
            {
                color: #414141;
                display: block;
                float: left;
                font-family: Arial, 'DejaVu Sans', 'Liberation Sans', Freesans, sans-serif;
                font-size: 12px;
                margin-top: 13px;
                margin-left: 10px;
                text-shadow: 0 1px 0 #ffffff;
            }
        }

        .vDialogBody
        {
            .FormRow
            {
                li.Label
                {
                    color: #414141;
                    text-shadow: 0 1px 0 #ffffff;
					
                    span.Details
                    {
                        color: #414141;
                    }

                }

                li.Field > input:focus
                {
                    box-shadow: 0px 0px 10px #9e9e9e;
                    outline: none;
                }
            }
        }
    }
}

/*#endregion*/

/*#region vDataPager */

.vDataPager
{
	background-color: $bg-grey;
    height: 40px;
	position: relative;
	width: 100%;

    .DataPagerRow
    {
        @include
        default-ul;
		display: block;
        height: 25px;
		left: 40%;
        padding: 8px 8px;
		position: absolute;
		width: 340px;
		
		li
		{
			display: inline;
			font: normal normal 12px Arial, Verdana, Sans-Serif;
			
			span
			{
				background-color: $main-blue;
				color: #fff;
				float: left;
				margin-right: 3px;
				padding: 3px 6px;
			}

			&.DataPageSelected,
			&:hover
			{
				color: $light-grey;
				font-weight: bold;
				text-decoration: none;
			}

			a
			{
				color: #807740;
				display: block;
				float: left;
				font: secondary-fontfamily;
				margin-right: 3px;
				padding: 3px 6px;
			}

			&.DataPageSelected,
			&:hover a
			{
				background-color: $main-blue;
				color: #fff;
			}
		}


    }

    .DataPagerDetails
    {
		left: 10px;
		padding: 10px;
		position: absolute;
		width: 100px;
    }
}

/*#endregion */

/*#region vCheckbox */
.vCheckbox {
       background: transparent url('../images/radio-check-sprite.png') 0px 0px no-repeat;
       border: 0px;
       cursor: pointer;
       float: left;
       height: 21px; 
       margin-right: 2px;
       width: 23px;  

       &:hover {
              background-position: -23px 0px;
       }

       &.vCheckboxDisabled {
              background-position: -46px 0px;
       }

       &.vCheckboxChecked {
              background-position: -69px 0px;

              &:hover {
                     background-position: -69px 0px;
              }

              &.vCheckboxDisabled {
                     background-position: -92px 0px;
              }
       }
}

.vRadio {
       background: transparent url('../images/radio-check-sprite.png') 0px -21px no-repeat;
       border: 0px;
       cursor: pointer;
       float: left;
       height: 21px; 
       margin-right: 2px;
       width: 23px;  

       &:hover {
              background-position: -23px -21px;
       }

       &.vRadioDisabled {
              background-position: -46px -21px;
       }

       &.vRadioChecked {
              background-position: -69px -21px;

              &:hover {
                     background-position: -69px -21px;
              }

              &.vRadioDisabled {
                     background-position: -92px -21px;
              }
       }
}

/*#endregion */

#AddressAndContactInfo
{
	margin: 20px 0px;

    #TennscoMap
    {
		border: 1px solid $main-grey;
        float: left;
        width: 300px;
    }

    #TennscoInfo
    {
        float: left;
		line-height: 18px;
        padding-left: 10px;
        width: 200px;

        span
        {
            display: block;

			&.Name
			{				
				font-size: 14px;
				font-weight: bold;
			}
        }
    }
}

#MyWishListItems
{
    @include
    default-ul;

    .Divider
    {
        border-bottom: 1px solid $main-grey;
    }

    .MyWishListItem
    {
		background: url('/Images/WishList-ItemArrow.png') no-repeat center left;
        border-bottom: 1px solid $main-grey;
		height: 222px;

		.ProductImage
		{
			display: block;
			float: left;
            height: 222px;
            line-height: 222px;
			margin-left: 110px;
            text-align: center;
            vertical-align: middle;
            width: 122px;
			img
			{
			}
		}

        .ProductInfo
        {
			color: $light-grey;
			float: left;
			margin-left: 30px;
			padding-top: 30px;
			width: 400px;

            .ProductNameWeb
            {
				color: $main-blue;
				display: block;
				font-size: 18px;
				height: 30px;
            }
			
            .ProductNamePrint
            {
				color: $main-blue;
				font-size: 18px;
				display: none;
            }

            .ProductSKU
            {
				display: block;
				font-size: 14px;
				height: 30px;
            }

            .ProductDimensions
            {
				display: block;
				font-size: 14px;
				height: 30px;
            }

            .ProductRemove
            {
				display: block;
				font-size: 10px;
				margin-top: 50px;

                .Remove
                {
					font-size: 14px;
					margin-right: 5px;
                }
            }
        }
    }
}

.Form
{
    .Mid
    {
        width: 270px;
    }

    .FirstColumn
    {
        float: left;
        width: 300px;
    }

    .SecondColumn
    {
        float: left;
		margin-left: 10px;
        width: 350px;
    }

    .FirstColumn,
    .SecondColumn
    {
        font-size: 11px;

        .SectionLabel
        {
            display: block;
			font-size: 13px;
            font-weight: bold;
        }

        .FieldLabel
        {
            display: block;
        }

        .FieldInput
        {
            display: block;

			input[type="radio"]
			{
				display: block;
				float: left;
			}

            label
            {
                display: block;
				float: left;
				font-size: 11px;
            }
        }

        .CheckboxList
        {
            @include
            default-ul;

            li
            {
                float: left;
                width: 170px;
				
				label
				{
					font-size: 11px;
					padding-left: 5px;
					width: 160px;
				}
            }
        }
    }

	.Buttons
	{
		.Padding
		{
			padding: 0 20px;
		}
	}
}

/*#region Validation */

.inputContainer {
	position: relative;
	float: left;
}

.formError {
	position: absolute;
	top: 300px;
	left: 300px;
	display: block;
	z-index: 5000;
	cursor: pointer;
}

.ajaxSubmit {
	padding: 20px;
	background: #55ea55;
	border: 1px solid #999;
	display: none
}

.greenPopup .formErrorContent {
	background: #33be40;
}

.blackPopup .formErrorContent {
	background: #393939;
	color: #FFF;
}

.greenPopup .formErrorArrow div {
	background: #33be40;
}

.blackPopup .formErrorArrow div {
	background: #393939;
	color: #FFF;
}

body[dir='rtl'] .formError .formErrorArrow,
body.rtl .formError .formErrorArrow {
	margin: -2px 13px 0 0;
}

.formError
{
	.formErrorArrowBottom {
		box-shadow: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		margin: 0px 0 0 12px;
		top: 2px;
		
		div {
		  box-shadow: none;
		  -moz-box-shadow: none;
		  -webkit-box-shadow: none;
		}
	}

	.formErrorContent {
		width: 100%;
		background: #900000;
		position:relative;
		z-index:5001;
		color: #fff;
		width: 150px;
		font-family: "Helvetica Neue", "Lucida Grande", "Segoe UI", Arial, Helvetica, Verdana, sans-serif;
		font-size: 11px;
		border: 2px solid #ddd;
		box-shadow: 0 0 6px #414141;
		-moz-box-shadow: 0 0 6px #414141;
		-webkit-box-shadow: 0 0 6px #414141;
		padding: 5px;
		border-radius: 3px;
		-moz-border-radius: 3px;
		-webkit-border-radius: 3px;
	}
	
	.formErrorArrow {
		width: 15px;
		margin: -2px 0 0 13px;
	  position:relative;
	  z-index: 5006;
	
		div {
			border-left: 2px solid #ddd;
			border-right: 2px solid #ddd;
			box-shadow: 0 2px 3px #444;
			-moz-box-shadow: 0 2px 3px #414141;
			-webkit-box-shadow: 0 2px 3px #414141;
			font-size: 0px;
			height: 1px;
			background: #900000;
			margin: 0 auto;
			line-height: 0;
			font-size: 0;
			display: block;
		}

		.line10 {
			width: 15px;
			border: none;
		}

		.line9 {
			width: 13px;
			border: none;
		}

		.line8 {
			width: 11px;
		}

		.line7 {
			width: 9px;
		}

		.line6 {
			width: 7px;
		}

		.line5 {
			width: 5px;
		}

		.line4 {
			width: 3px;
		}

		.line3 {
			width: 1px;
			border-left: 2px solid #ddd;
			border-right: 2px solid #ddd;
			border-bottom: 0 solid #ddd;
		}

		.line2 {
			width: 3px;
			border: none;
			background: #ddd;
		}

		.line1 {
			width: 1px;
			border: none;
			background: #ddd;
		}
	}
}


/*#endregion */